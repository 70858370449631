import React, { useCallback, useMemo } from "react";
import { useAppState } from "../../AppContext";
import {
  StyledMapLayerGroups,
  LayerGroupLink,
  LayerGroupListItem,
  LayerGroupInput,
  LayerGroupTitle,
  LayerGroupContent,
  LayerGroupZoomButton,
} from "./MapLayerGroupsStyles";
import { useRouteState } from "../../hooks/useRouteState";
import classNames from "classnames";
import { MemoizedWordpressContentWrapper } from "../../shared/components/TextWrapper";
import { LayerGroupScenarioSwitch } from "./LayerGroupScenarioSwitch";
import { Link } from "react-router-dom";
import { ReactComponent as LinkArrowRight } from "../../images/linkArrowRight.svg";
import { getFaqItems } from "../../utils/faqUtils";
import { useLayerGroupUrl } from "../../hooks/useLayerGroupUrl";
import { useUrlState } from "../../hooks/useUrlState";
import { useContentUrl } from "../../hooks/useContentUrl";
import { useFaqUrl } from "../../hooks/useFaqUrl";
import { LayerGroup, MenuItem } from "../../types";
import { Feature, useFeature } from "../../hooks/useFeature";
import { FormattedMessage } from "react-intl";
import { messages } from "../../global-intl-messages";
import { breakpoints, sidebarWidth } from "../../utils/styleUtils";
import { useSetMapState } from "../../hooks/useSetMapState";
import WebMercatorViewport from "viewport-mercator-project";
import { useInterfaceState } from "../../InterfaceContext";

export default function MapLayerGroups({ menuItem }: { menuItem: MenuItem }) {
  const { state } = useAppState();
  const routeState = useRouteState(true);
  const layerGroupUrl = useLayerGroupUrl();
  const { setInterfaceState } = useInterfaceState();
  const urlState = useUrlState();
  const contentUrl = useContentUrl();
  const faqUrl = useFaqUrl();
  const faqsAvailable = useFeature(Feature.Faqs);
  const setMapState = useSetMapState();

  const singleLayer = state.map.layerGroups.filter((l) => l.showInMenu).length === 1;

  const shouldShow = useCallback(
    (layerGroup: LayerGroup) => {
      if (!urlState.embed || routeState.activeLayerGroupSlug === layerGroup.slug) {
        return menuItem.children?.some((c) => c.page === layerGroup.id) || layerGroup.showInMenu;
      }
      return false;
    },
    [menuItem, routeState.activeLayerGroupSlug, urlState.embed]
  );

  const layerGroupListItems = useMemo(() => {
    const shown = state.map.layerGroups.filter(shouldShow);

    return shown.sort((layerGroupA, layerGroupB) => {
      if (!menuItem.children?.length) return 0;

      const indexA = menuItem.children.findIndex((c) => c.page === layerGroupA.id);
      const indexB = menuItem.children.findIndex((c) => c.page === layerGroupB.id);

      return indexA - indexB;
    });
  }, [menuItem, state.map.layerGroups, shouldShow]);

  const zoomToLayerGroup = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent, layerGroup: LayerGroup) => {
      if (!layerGroup.bounds) return;

      const padding = {
        left: matchMedia(breakpoints[30]).matches ? sidebarWidth : 0,
        right: 0,
        top: 0,
        bottom: 0,
      };
      const { innerWidth, innerHeight } = window;
      const viewport = new WebMercatorViewport({
        width: innerWidth,
        height: innerHeight,
      }).fitBounds(layerGroup.bounds, {
        padding,
      });
      const zoomlevel =
        viewport.zoom < state.mapConfig.areaInflectionPoint
          ? state.mapConfig.areaInflectionPoint
          : viewport.zoom;
      setMapState(viewport.latitude, viewport.longitude, zoomlevel);
      e.preventDefault();
    },
    [setMapState, state.mapConfig.areaInflectionPoint]
  );

  return (
    <StyledMapLayerGroups>
      {layerGroupListItems.map((layerGroup) => {
        // Show all legend items of all scenarios for consistent interface
        return (
          <LayerGroupListItem
            key={layerGroup.slug}
            className={classNames({
              active: routeState.activeLayerGroupSlug === layerGroup.slug || singleLayer,
            })}
            onClick={() => setInterfaceState((state) => ({ ...state, legendClicked: undefined }))}
          >
            <LayerGroupLink to={layerGroupUrl(layerGroup)}>
              <LayerGroupInput />
              <LayerGroupTitle>{layerGroup.title}</LayerGroupTitle>
            </LayerGroupLink>
            <LayerGroupContent>
              {layerGroup.excerpt && (
                <MemoizedWordpressContentWrapper content={layerGroup.excerpt} />
              )}
              {layerGroup.content && layerGroup.excerpt !== layerGroup.content && (
                <Link to={contentUrl(layerGroup.slug)}>
                  <FormattedMessage {...messages.readMore} /> <LinkArrowRight />
                </Link>
              )}
              {faqsAvailable && getFaqItems(layerGroup.slug, state.faqs).length !== 0 && (
                <Link to={faqUrl(layerGroup.slug)}>
                  <FormattedMessage
                    id="maplayer-list.view-faqs"
                    defaultMessage="View frequently asked questions"
                    description="Label for a link navigating to relevant frequently asked questions."
                  />{" "}
                  <span className="icon">
                    <LinkArrowRight />
                  </span>
                </Link>
              )}
              <LayerGroupScenarioSwitch />
              {!state.mapConfig.autozoomToActiveLayers && layerGroup.bounds && (
                <LayerGroupZoomButton
                  onClick={(event: React.MouseEvent<Element>) =>
                    zoomToLayerGroup(event, layerGroup)
                  }
                >
                  <FormattedMessage
                    id="maplayer-list.zoom-to-layer"
                    defaultMessage="Zoom to layer"
                    description="Label for a button that zooms to the layer"
                  />
                </LayerGroupZoomButton>
              )}
            </LayerGroupContent>
          </LayerGroupListItem>
        );
      })}
    </StyledMapLayerGroups>
  );
}
