import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { useAppState } from "../../AppContext";
import { useSessionStorage } from "../../hooks/useStorage";
import { ReactComponent as DragTooltip } from "./../../images/drag-tooltip.svg";
import { ReactComponent as PinchTooltip } from "./../../images/pinch-tooltip.svg";

const SplashContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  transition: opacity 0.3s;
  z-index: 10000000;
  color: white;
  vertical-align: middle;

  > h1 {
    margin-bottom: 2rem;
  }

  > div {
    display: flex;
    align-items: center;
    margin: var(--space-stack-30);

    > p {
      line-height: 40px;
      margin: 0;
      margin-left: 8px;
    }
  }

  &.exiting {
    opacity: 0;
    pointer-events: none;
  }
`;

const SplashBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: radial-gradient(circle at 30% 10%, var(--color-primary-30), var(--color-primary-70));
  opacity: 0.8;
  background-size: cover;
  z-index: -1;
`;

export const Splash = () => {
  const [exiting, setExiting] = useState(false);
  const { state } = useAppState();
  const [closedSplash, setClosedSplash] = useSessionStorage("closedSplash");

  const closeSplash = useCallback(() => {
    setExiting(true);
    setTimeout(() => {
      setClosedSplash("true");
    }, 300);
  }, [setClosedSplash]);

  if (closedSplash) return null;

  return (
    <SplashContainer onClick={closeSplash} className={exiting ? "exiting" : ""}>
      <SplashBackground />
      <h1>{state.name}</h1>
      <div>
        <DragTooltip />
        <FormattedMessage
          id="splash.drag"
          defaultMessage="Drag in photos to look around"
          description="tooltip for drag on the splash screen"
        />
      </div>
      <div>
        <PinchTooltip />
        <FormattedMessage
          id="splash.zoom"
          defaultMessage="Pinch or scroll to zoom in and out"
          description="tooltip for zoom on the splash screen"
        />
      </div>
    </SplashContainer>
  );
};
