import { useParams, useLocation, matchPath } from "react-router-dom";
import { useAppState } from "../AppContext";
import { useMemo } from "react";
import { useUrlState } from "./useUrlState";

export interface RouteParams {
  layerGroupSlug: string;
  contentSlug: string;
  faqSlug: string;
  panoSlug: string;
  scenarioSlug: string;
  sceneSlug: string;
  participationItemSlug: string;
  participationItemResultSlug: string;
  poiSlug: string;
  projectSlug: string;
  phaseSlug: string;
  tourSlug: string;
  tourPointSlug: string;
  map: "map";
  question: "question";
}

export function useRouteState(extra = false) {
  const params = useParams<Partial<RouteParams>>();
  const { pathname } = useLocation();
  const urlState = useUrlState();
  const { state } = useAppState();

  const pathWithoutEncodedStateOrQuery = useMemo(() => pathname.replace(/[?@].*/, ""), [pathname]);

  const extraParams = useMemo(() => {
    if (extra) {
      return paramMatch(pathWithoutEncodedStateOrQuery, [
        "*/tour/:tourSlug",
        "*/step/:tourPointSlug",
        "*/c/:contentSlug",
        "*/p/:poiSlug",
        "*/faq/:faqSlug",
        "*/scene/:sceneSlug",
        "*/participation/:participationItemSlug",
        "*/participation-result/:participationItemResultSlug",
        "*/pano/:panoSlug",
        "*/layer/:layerGroupSlug",
        "*/:map(map)",
        "*/:question(question)",
      ]);
    }
  }, [pathWithoutEncodedStateOrQuery, extra]);

  Object.assign(params, extraParams);

  const routeState = useMemo(() => {
    // Make set off all layer groups
    const layerGroupSlugs = new Set<string>();
    state.map?.layerGroups.forEach((layerGroup) => {
      layerGroupSlugs.add(layerGroup.slug);
    });

    // If no valid layer is selected, select the default as the active one
    let layerGroupSlug;
    let activeLayerGroupSlug = state.map?.defaultLayerGroupSlug;
    if (params.layerGroupSlug && layerGroupSlugs.has(params.layerGroupSlug)) {
      layerGroupSlug = params.layerGroupSlug;
      activeLayerGroupSlug = params.layerGroupSlug;
    }

    const scenarioSlugs = new Set<string>();
    state.scenarios?.forEach((scenario) => {
      scenarioSlugs.add(scenario.slug);
    });

    let scenarioSlug;
    let activeScenarioSlug = state.defaultScenarioSlug;
    if (scenarioSlugs.has(urlState.scenarioSlug)) {
      scenarioSlug = urlState.scenarioSlug;
      activeScenarioSlug = urlState.scenarioSlug;
    }

    return {
      ...params,
      layerGroupSlug,
      scenarioSlug,
      activeLayerGroupSlug,
      activeScenarioSlug,
      pathname,
    };
  }, [
    params,
    state.map?.defaultLayerGroupSlug,
    state.map?.layerGroups,
    state.scenarios,
    state.defaultScenarioSlug,
    urlState.scenarioSlug,
    pathname,
  ]);

  return routeState;
}

const paramMatch = (path: string, looseParams: string[]) => {
  return looseParams.reduce<Partial<RouteParams>>((params, p) => {
    return {
      ...params,
      ...(matchPath<RouteParams>(path, p)?.params ?? {}),
    };
  }, {});
};
