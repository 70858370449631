import { useGeneratePath } from "./useGeneratePath";

export function usePanoUrl() {
  const generatePath = useGeneratePath();

  const panoUrl = (panoSlug: string) => {
    return generatePath(
      {
        panoSlug,
        poiSlug: undefined,
        contentSlug: undefined,
        faqSlug: undefined,
        tourSlug: undefined,
        tourPointSlug: undefined,
        projectSlug: undefined,
        phaseSlug: undefined,
        sceneSlug: undefined,
        map: undefined,
      },
      false,
      false
    );
  };

  return panoUrl;
}
