import React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useAppState } from "../../AppContext";
import { messages } from "../../global-intl-messages";
import { useCopy } from "../../hooks/useCopy";
import { useFeature, Feature } from "../../hooks/useFeature";
import { useQuestionUrl } from "../../hooks/useQuestionUrl";
import { useRouteState } from "../../hooks/useRouteState";
import Action from "../../shared/components/Action";
import ActionList from "../../shared/components/ActionList";
import { shareView } from "../../utils/sharedUtils";
import { ShareModal } from "../Modals/ShareModal";
import { ReactComponent as BubbleIcon } from "./../../images/bubble.svg";
import { ReactComponent as ShareIcon } from "./../../images/share.svg";

type Props = {
  alignRight?: boolean;
};

const mobileDevices = ["Android", "iPhone"];

const MapActions = ({ alignRight }: Props) => {
  const routeState = useRouteState();
  const questionUrl = useQuestionUrl();
  const { state } = useAppState();
  const copy = useCopy();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const history = useHistory();
  const shareAvailable = useFeature(Feature.Sharing);
  const askQuestionAvailable = useFeature(Feature.AskQuestion);

  const intl = useIntl();

  const style = alignRight ? { right: 16 } : { left: 16 };

  return (
    <MapActionsContainer style={style}>
      {!routeState.participationItemSlug && (
        <ActionList>
          {askQuestionAvailable && state.qaSurveyId && (
            <Action
              icon={<BubbleIcon />}
              onClick={() => {
                history.push(questionUrl(true));
              }}
            >
              <FormattedMessage {...messages.askQuestionButton} />
            </Action>
          )}
          {shareAvailable && (
            <>
              <Action
                icon={<ShareIcon />}
                onClick={() => {
                  if (
                    mobileDevices.some((device) => navigator.userAgent.includes(device)) &&
                    navigator.share !== undefined
                  ) {
                    shareView(
                      state,
                      intl.formatMessage(messages.copyShareLinkSuccessMessage),
                      intl.formatMessage(messages.copyShareLinkFailedMessage),
                      copy
                    );
                  } else {
                    setShareModalOpen(true);
                  }
                }}
              >
                <FormattedMessage {...messages.shareButton} />
              </Action>

              <ShareModal open={shareModalOpen} onClose={() => setShareModalOpen(false)} />
            </>
          )}
        </ActionList>
      )}
    </MapActionsContainer>
  );
};

const MapActionsContainer = styled.div`
  position: absolute;
  z-index: 41;
  top: 16px;
`;

export const MemoizedMapActions = React.memo(MapActions);
