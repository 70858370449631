import { replaceSlashes } from "../utils/routeUtils";
import { generatePath as reactRouterGeneratePath } from "react-router-dom";
import { useGetStatePath } from "./useGetStatePath";
import { RouteParams, useRouteState } from "./useRouteState";
import { useCallback } from "react";
import { useInterfaceState } from "../InterfaceContext";

export function useGeneratePath() {
  const {
    layerGroupSlug,
    panoSlug,
    poiSlug,
    faqSlug,
    contentSlug,
    sceneSlug,
    participationItemSlug,
    participationItemResultSlug,
    tourSlug,
    tourPointSlug,
    projectSlug,
    phaseSlug,
    map,
    question,
  } = useRouteState(true);
  const getStatePath = useGetStatePath();
  const { interfaceState } = useInterfaceState();

  const generatePath = useCallback(
    (params: Partial<RouteParams>, includePanoState: boolean, includeMapState: boolean) => {
      const generatedParams: Partial<RouteParams> = {
        layerGroupSlug,
        panoSlug,
        poiSlug,
        contentSlug,
        faqSlug,
        sceneSlug,
        participationItemSlug,
        participationItemResultSlug,
        tourSlug,
        tourPointSlug,
        projectSlug,
        phaseSlug,
        map,
        question,
        ...params,
      };
      let entranceType =
        interfaceState.currentEntrance?.type ?? (generatedParams.panoSlug ? "pano" : "map");

      const isTour = !!generatedParams.tourSlug;

      if (params.faqSlug && generatedParams.contentSlug) generatedParams.contentSlug = undefined;
      if (params.contentSlug && generatedParams.faqSlug) generatedParams.faqSlug = undefined;

      if (entranceType !== "map" && !generatedParams.panoSlug)
        generatedParams.panoSlug = interfaceState.currentEntrance.pano.slug;
      if (entranceType === "map") generatedParams.map = "map";

      // final pattern (segments within parentheses are optional):
      //  (pano-/vcrbased) -> /pano/:panoSlug/(map)/(layer/:layerGroupSlug)/(scene/:sceneSlug)/(p/:poiSlug)/(c/:contentSlug)/(faq/:faqSlug)/(participation/:participationItemSlug)/(participation-result/:participationItemResultSlug)/(project/:projectSlug)/(phase/:phaseSlug)/(question)
      //  (mapbased) -> /map/(layer/:layerGroupSlug)/(pano/:panoSlug)/(scene/:sceneSlug)/(p/:poiSlug)/(c/:contentSlug)/(faq/:faqSlug)/(participation/:participationItemSlug)/(participation-result/:participationItemResultSlug)/(project/:projectSlug)/(phase/:phaseSlug)/(question)
      //  (tour) -> /tour/:tourSlug/(step/:tourPointSlug)/(p/:poiSlug)/(c/:contentSlug)/(faq/:faqSlug)/(participation/:participationItemSlug)/(participation-result/:participationItemResultSlug)/(project/:projectSlug)/(phase/:phaseSlug)/(question)
      const pattern =
        "/" +
        (generatedParams.tourSlug ? "tour/:tourSlug/" : "") +
        (generatedParams.tourSlug && generatedParams.tourPointSlug ? "step/:tourPointSlug/" : "") +
        (!isTour && entranceType !== "map" ? "pano/:panoSlug/" : "") +
        (!isTour && generatedParams.map ? "map/" : "") +
        (generatedParams.layerGroupSlug ? "layer/:layerGroupSlug/" : "") +
        (entranceType === "map" && !isTour && generatedParams.panoSlug ? "pano/:panoSlug/" : "") +
        (generatedParams.sceneSlug ? "scene/:sceneSlug/" : "") +
        (generatedParams.poiSlug ? "p/:poiSlug/" : "") +
        (generatedParams.contentSlug && !generatedParams.faqSlug ? "c/:contentSlug/" : "") +
        (generatedParams.faqSlug && !generatedParams.contentSlug ? "faq/:faqSlug/" : "") +
        (generatedParams.participationItemSlug ? "participation/:participationItemSlug/" : "") +
        (generatedParams.participationItemResultSlug
          ? "participation-result/:participationItemResultSlug/"
          : "") +
        (generatedParams.projectSlug ? "project/:projectSlug/" : "") +
        (generatedParams.projectSlug && generatedParams.phaseSlug ? "/phase/:phaseSlug/" : "") +
        (generatedParams.question ? "question/" : "");

      return (
        replaceSlashes(reactRouterGeneratePath(pattern, generatedParams)) +
        getStatePath(includePanoState, includeMapState) +
        window.location.search
      );
    },
    [
      layerGroupSlug,
      panoSlug,
      poiSlug,
      faqSlug,
      contentSlug,
      sceneSlug,
      participationItemSlug,
      participationItemResultSlug,
      tourSlug,
      tourPointSlug,
      projectSlug,
      phaseSlug,
      map,
      question,
      interfaceState.currentEntrance,
      getStatePath,
    ]
  );

  return generatePath;
}
