import { AppState } from "../types";

export const shareView = (
  state: AppState,
  successMessage: string,
  failedMessage: string,
  copy: (text: string) => Promise<void>
) => {
  try {
    navigator
      .share({
        url: window.location.href,
        text: state.description,
        title: state.name,
      })
      .then(
        () => alert(successMessage),
        () => alert(failedMessage)
      );
  } catch (error) {
    copy(window.location.href).then(
      () => alert(successMessage),
      () => alert(failedMessage)
    );
  }
};
