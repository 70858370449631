import { createGlobalStyle } from "styled-components";
import { StyleOverrides } from "./types";
import { sidebarWidth } from "./utils/styleUtils";

interface Props {
  overrides: StyleOverrides;
}

// Formatting of createGlobalStyle doesn't work with Prettier
// change it to css and, save, and back to createGlobalStyle
// as a fix.
export default createGlobalStyle<Props>`
  /*------------------------------------*\
  #TOKENS
\*------------------------------------*/

  /*
 * Most of the variables are based on some kind of percentages to maintain stability and consistency.
 * So --variable-10 is small or light and --variable-100 is big or dark.
 */
  @font-face {
    font-family: 'Proxima Nova';
    src: url("/fonts/ProximaNovaSemibold.otf");
    font-weight: 600;
  }
  @font-face {
    font-family: 'Proxima Nova';
    src: url("/fonts/ProximaNovaBold.otf");
    font-weight: 700;
  }
  @font-face {
    font-family: 'Proxima Nova';
    src: url("/fonts/ProximaNovaSemiboldItalic.otf");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: 'Proxima Nova';
    src: url("/fonts/ProximaNovaBoldItalic.otf");
    font-weight: 700;
    font-style: italic;
  }

  :root {
    /* Fonts */
    --font-family-body: ${({overrides}) => overrides["font-family-body"]};
    --font-family-heading: ${({overrides}) => overrides["font-family-heading"]};

    /* Font size */
    --font-size-base: 16px;

    --font-size-default: var(--font-size-20);
    --font-size-10: 14px;
    --font-size-20: 15px;
    --font-size-30: 16px;
    --font-size-40: 19px; /* Not sure if we should use this one */
    --font-size-50: var(--font-size-40);
    --font-size-60: 22px;
    --font-size-70: 26px;

    /* Line-height */
    --line-height-default: var(--line-height-10);
    --line-height-10: 1em;
    --line-height-20: 1.35em;
    --line-height-30: 1.5em;

    /* Letter spacing */
    --letter-spacing-default: 2px;

    /* Font weight */
    --font-weight-default: var(--font-weight-20);
    --font-weight-10: 300;
    --font-weight-20: 400;
    --font-weight-30: 600;

    /*
    * Spacing
    */

    --sidebar-width: ${sidebarWidth}px;

    /* Spacing system */
    --space-default: var(--space-30);
    --space-10: 4px;
    --space-20: 8px;
    --space-25: 12px; /* Not default */
    --space-30: 16px;
    --space-40: 32px;
    --space-50: 64px;

    --space-stack-default: var(--space-stack-30);
    --space-stack-10: 0 0 4px 0;
    --space-stack-20: 0 0 8px 0;
    --space-stack-25: 0 0 12px 0;
    --space-stack-30: 0 0 16px 0;
    --space-stack-40: 0 0 32px 0;
    --space-stack-50: 0 0 64px 0;

    --space-inset-default: var(--space-inset-30);
    --space-inset-10: 4px 4px 0.1px 4px;
    --space-inset-20: 8px 8px 0.1px 8px;
    --space-inset-30: 16px 16px 0.1px 16px;
    --space-inset-40: 32px 32px 16px 32px;
    --space-inset-50: 64px 64px 48px 64px;

    --space-squish-10: 4px 8px;
    --space-squish-20: 8px 16px;
    --space-squish-25: 12px 24px; /* Not default */
    --space-squish-30: 16px 32px;

    /* Grid gutter */
    --gutter: 32px;

    /* Maximum width of the website */
    --max-width: 1140px;

    /*
    * Colors
    */

    /* Primary */
    --color-primary-20: ${({overrides}) => overrides["color-primary-20"]};
    --color-primary-30: ${({overrides}) => overrides["color-primary-30"]};
    --color-primary-50: ${({overrides}) => overrides["color-primary-50"]};
    --color-primary-70: ${({overrides}) => overrides["color-primary-70"]};

    /* Success and positive colors */
    --color-success-30: ${({overrides}) => overrides["color-success-30"]};
    --color-success-50: ${({overrides}) => overrides["color-success-50"]};
    --color-success-70: ${({overrides}) => overrides["color-success-70"]};

    /* Error and negative colors */
    --color-error-30: ${({overrides}) => overrides["color-error-30"]};
    --color-error-50: ${({overrides}) => overrides["color-error-50"]};
    --color-error-70: ${({overrides}) => overrides["color-error-70"]};

    /* Neutral or gray colors */
    --color-neutral-10: #F0EFF3;
    --color-neutral-20: #E5E4EA; /* Only used for hover */
    --color-neutral-30: #C0BECF;
    --color-neutral-40: #9896AF; /* Only used for label below survey question */
    --color-neutral-50: #6F6C8A;
    --color-neutral-70: #3D3A58;
    --color-neutral-90: #191825;

    /* Informative colors */
    --color-informative-30: ${({overrides}) => overrides["color-informative-30"]};
    --color-informative-50: ${({overrides}) => overrides["color-informative-50"]};
    --color-informative-70: ${({overrides}) => overrides["color-informative-70"]};

    /* Black and white */
    --color-black: #000000;
    --color-white: #FFFFFF;

    /* Wordpress color-select options */
    --color-a: #5542E5;
    --color-b: #FFA35C;
    --color-c: #DC3984;
    --color-d: #2AB8D4;
    --color-e: #099F5E;
    --color-f: #5F9FFF;
    --color-g: #FFD052;
    --color-h: #CF39DC;
    --color-i: #0EC6A5;
    --color-j: #A8D42A;
    --color-k: #8733EB;
    --color-l: #F95528;
    --color-m: #DC3958;
    --color-n: #2AD47A;
    --color-o: #4CB41B;
    /*
    * Other
    */

    /* Rounded borders */
    --border-radius-default: var(--border-radius-10);
    --border-radius-10: 3px;
    --border-radius-15: 8px;
    --border-radius-20: 12px;
    --border-radius-50: var(--border-radius-10);

    /* Shadows */
    --box-shadow-default: var(--box-shadow-10);
    --box-shadow-10: 0px 2px 4px 1px rgba(0,0,0,0.1);
    --box-shadow-20: 0px 1px 4px 2px rgba(25,24,37,0.1),0px 0px 1px rgba(25,24,37,0.3);
    --box-shadow-25: 0px 1px 8px rgba(25,24,37,0.4);
    --box-shadow-30: 0px 2px 16px rgba(25,24,37,0.2);
    --box-shadow-30b: drop-shadow(0px 2px 16px rgba(25,24,37,0.1));
    --box-shadow-inset: inset 0 8px 8px -8px rgba(0,0,0,0.3);

    /* Animation beziers */
    --bezier-a: cubic-bezier(0.16, 1, 0.3, 1);

    /* Z-index plan */
    --layer-10: 10;
    --layer-20: 20;
    --layer-30: 30;
  }

  /* Breakpoints moved to utils/styles.ts */
`;

/** Escape hatch to use css variables in components which do not support css-variables, like data-URI's */
export const getTokenValue = (token: string) =>
  token.startsWith("--") ? getComputedStyle(document.documentElement).getPropertyValue(token) : token;
